import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";
import FightingGameImage from "../images/FightingGame.gif";
import WeatherAppImage from "../images/WeatherApp.png";
import WebsiteImage from "../images/Website.png";
import FirstTo100Image from "../images/FirstTo100.gif";
import TikTakToeImage from "../images/Tik-Tak-Toe.gif";
import BallGameImage from "../images/BallGame.gif";
import projectsBG from "../images/projectsBG.png";
import AnimalSpotr from "../images/AnimalSpotr.gif";
const projects = [
  {
    title: "Animal Spotr",
    description:
      "Watch the video and answer questions all while learning interssting thing about animals.",
    getImageSrc: AnimalSpotr,
    url: "https://animal-spotr.grant-dennison.co.uk/",
  },
  {
    title: "2D Canvas Fighting Game",
    description:
      "Fun fighting game using JavaScript, HTML & Canvas. Have a go at this two player game.",
    getImageSrc: FightingGameImage,
    url: "https://grants-2d-fighting-game.netlify.app/",
  },
  {
    title: "Cancas Weather App",
    description:
      "A weather app using TypeScript and canvas that makes an API call to fetch the required data. It also utilizes Jest for testing.",
    getImageSrc: WeatherAppImage,
    url: "https://grant-dennison.co.uk/weather-app-canvas/",
  },
  {
    title: "Child Minding Website",
    description: "A child-minding website using only HTML and CSS",
    getImageSrc: WebsiteImage,
    url: "https://github.com/grantdennison/Gosias-home-from-home",
  },
  {
    title: "First to 100 Wins",
    description:
      "First player to reach 100 roll the dice and hold. More you roll the greater risk to reward. Have a go and test your nerves",
    getImageSrc: FirstTo100Image,
    url: "https://grantdennison.github.io/first-to-100/index.html",
  },
  {
    title: "Tik-Tak-Toe React Game",
    description:
      "First react game of popular game tik-tak-toe. Fun to play and good way to learn react.",
    getImageSrc: TikTakToeImage,
    url: "https://github.com/grantdennison/tic-tac-toe",
  },
  {
    title: "Ball Game - Collect red dots",
    description:
      "Collect the red dots, more you collect the faster you go! Try beat your high score. Enjoy.",
    getImageSrc: BallGameImage,
    url: "https://grantdennison.github.io/ball-game/index.html",
  },
];

export default function ProjectsSection() {
  return (
    <Box
      position="relative"
      justifyContent="center"
      alignItems="center"
      bgImage={projectsBG}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      minH="90vh"
      p={[2, 2, 6]}
    >
      {/* Overlay Box */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="blackAlpha.400"
        zIndex="1" // Ensure the overlay is behind the content
      />

      {/* Content Box */}
      <Box position="relative" zIndex="2" p={5}>
        {" "}
        {/* Add padding if necessary */}
        <Heading
          as="h1"
          id="projects-section"
          size="xl"
          textAlign="center"
          color="white"
          pb={5}
          fontSize={[`30px`, `30px`, `40px`, `50px`]}
        >
          Featured Projects
        </Heading>
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
          gridGap={8}
          mt={5}
          fontFamily={`"Courier New", Courier, monospace`}
          color={`black`}
          fontWeight={`bold`}
        >
          {projects.map((project) => (
            <Card
              key={project.title}
              title={project.title}
              description={project.description}
              imageSrc={project.getImageSrc}
              url={project.url}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

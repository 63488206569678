import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faBars } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Link,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

// ######## SOCIALS ########

const socials = [
  {
    icon: faEnvelope,
    url: "mailto: grantdennison@yahoo.co.uk",
  },
  {
    icon: faGithub,
    url: "https://github.com/grantdennison",
  },
  {
    icon: faLinkedin,
    url: "https://www.linkedin.com/in/grant-dennison-8a1537b6/",
  },
];

// ######## NAVBAR LINKS ########

const links = [
  { id: "profile", text: "Profile", href: "/#profile" },
  { id: "projects", text: "Projects", href: "/#projects" },
  { id: "contact-me", text: "Contact Me", href: "/#contact-me" },
];

// ######## HEADER ########

export default function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navBarHeight = 80;

  // ######## HIDE NAVBAR ON SCROLL ########
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible]);

  // ######## SMOOTH SCROLLING ########
  const handleClick = (anchor) => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);

    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - navBarHeight + 10;

      window.scrollTo({
        top: window.scrollY + offsetPosition,
        behavior: "smooth",
      });
      onClose(); // Close the drawer after navigation
    }
  };

  // ######## RETURN ########
  return (
    <Box id="profile-section">
      <Box
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1rem"
        color="white"
        position="fixed"
        top="0"
        left="0"
        right="0"
        transform={visible ? "translateY(0)" : "translateY(-100%)"}
        transition="transform 0.3s ease-in-out"
        zIndex="1000"
        width="full"
        backgroundColor="#18181b"
        maxH={navBarHeight}
      >
        <Box color="white" maxWidth="1280px" margin="0 auto">
          <HStack px={1} justifyContent="space-between" alignItems="center">
            <HStack spacing={5}>
              {socials.map((social, index) => (
                <Link href={social.url} key={index} isExternal>
                  <FontAwesomeIcon icon={social.icon} size="2x" />
                </Link>
              ))}
            </HStack>
            <Spacer />
            <HStack display={[`none`, `none`, `flex`]}>
              {links.map((link) => (
                <Text
                  key={link.id}
                  cursor={"pointer"}
                  onClick={() => handleClick(link.id)}
                  px={2}
                  sx={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    transition: "transform 0.3s",
                    "&:hover": {
                      color: "rgb(11, 161, 242)",
                      transform: "scale(1.2)",
                    },
                  }}
                >
                  {link.text}
                </Text>
              ))}
            </HStack>
            <IconButton
              display={[`flex`, `flex`, `none`]}
              ref={btnRef}
              onClick={onOpen}
              icon={<FontAwesomeIcon icon={faBars} size="2x" />}
              bg={`none`}
              _hover={{
                transform: "scale(1.2)",
                color: "rgb(11, 161, 242)",
              }}
            />
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Grant Dennison</DrawerHeader>
                <DrawerBody>
                  <VStack spacing={4} align={`left`}>
                    {links.map((link) => (
                      <Text
                        key={link.id}
                        cursor={"pointer"}
                        onClick={() => handleClick(link.id)}
                        sx={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          "&:hover": {
                            color: "rgb(11, 161, 242)",
                            transform: "scale(1.1)",
                          },
                        }}
                      >
                        {link.text}
                      </Text>
                    ))}
                  </VStack>
                </DrawerBody>
                <DrawerFooter>
                  <Button variant="outline" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </HStack>
        </Box>
      </Box>
    </Box>
  );
}

import React from "react";
import { Box, Heading, Text, Link, VStack } from "@chakra-ui/react";
import contactBG from "../images/contactBG.png";

const ContactMeSection = () => {
  return (
    <Box
      p={5}
      borderRadius="lg"
      id="contact-me-section"
      bgImage={contactBG}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      color="white" // Added for better visibility against the background
    >
      <VStack
        spacing={4}
        align="center"
        w="full"
        fontFamily={`"Courier New", Courier, monospace`}
        fontWeight={`bold`}
        textShadow={`0px 0px 4px #FFFFFF`}
      >
        {" "}
        {/* Corrected align prop and added width */}
        <Heading as="h2" size="xl" textAlign="center">
          {" "}
          Contact Me
        </Heading>
        <Text textAlign="center" fontSize={[`12px`, `14px`, `20px`]}>
          Based in: Southend-On-Sea, United Kingdom
        </Text>{" "}
        <Text fontSize={[`12px`, `14px`, `20px`]} textAlign="center">
          Phone: +44 7915065420
        </Text>{" "}
        <Link
          href="mailto:grantdennison@yahoo.co.uk"
          isExternal
          fontSize={[`12px`, `14px`, `20px`]}
        >
          Email: grantdennison@yahoo.co.uk
        </Link>
        <Link
          href="https://www.fiverr.com/gdennison"
          isExternal
          fontSize={[`12px`, `14px`, `20px`]}
        >
          Fiverr: https://www.fiverr.com/gdennison
        </Link>
      </VStack>
    </Box>
  );
};

export default ContactMeSection;

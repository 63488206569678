import React from "react";
import { Avatar, Heading, VStack, Flex, Box, Text } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import Grant from "../images/Grant-Dennison.jpg";
import backGround from "../images/backGround.png";

import "./LandingSection.css";

const greeting = "Hello, My name is Grant Dennison!";

const bio1 = `I am Grant Dennison, a seasoned professional with a rich history in the railway industry and a passionate journey into programming. My expertise spans from intricate online monitoring and fault diagnosis to creative endeavors in home automation and game development. With proven proficiency in languages like JavaScript and frameworks such as React, I've evolved my skills through real-world projects like "Marks-Game" and professional freelance work, including a notable web-based game "AnimalSpotr". As I transition towards a career in JavaScript development, I bring a unique blend of technical know-how, problem-solving acumen, and a commitment to innovation, seeking to make a significant impact in the tech realm.`;

export default function LandingSection() {
  return (
    <Flex
      pos={"relative"}
      justifyContent="center"
      alignItems="center"
      bgImage={backGround}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      minH="90vh"
      w="100vw"
      direction="column"
      pt={[`80px`, `80px`, `0`]}
    >
      <Box
        pos={"absolute"}
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={`blackAlpha.700`}
        zIndex={2}
      ></Box>
      <VStack spacing={6} align="center" maxW="1200px" zIndex={2}>
        <Box display={{ base: "none", md: "flex" }} alignItems="center">
          <Avatar src={Grant} alt="Grant Dennison" size="2xl" />
          <Heading as="h1" size="2xl" color="white" ml={5}>
            {greeting}
          </Heading>
        </Box>
        <Box display={{ base: "block", md: "none" }} textAlign="center">
          <Avatar src={Grant} alt="Grant Dennison" size="xl" mb={4} />
          <Heading as="h1" size="xl" color="white">
            {greeting}
          </Heading>
        </Box>
        <Text
          color="white"
          px={4}
          textAlign="center"
          fontSize={[`16px`, `16px`, `20px`, `30px`]}
          className="landing_para"
        >
          {bio1}
        </Text>
      </VStack>
    </Flex>
  );
}
